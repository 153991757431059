<template>
    <div class="person-detail-page">
        <div class="hero">
            <HeroBasic
                :eyebrow="$t(eyebrowText, 1, { locale: pageLanguage })"
                :heading="title"
                :dek="personTitle"
                :accentColor="isExternal ? 'blanc' : 'blue'"
                eyebrowMargin
                hasHeaderOffset
                showTopographic
            >
                <template #image>
                    <Avatar v-if="avatar" size="x-large" :image="avatar" :isColor="true"></Avatar>
                </template>
            </HeroBasic>
        </div>

        <div class="sub-hero">
            <div class="sub-hero-content">
                <div class="link-panel">
                    <PageControls>
                        <template #left>
                            <div class="link-panel-left">
                                <template v-if="translationMenuItems.length > 1">
                                    <template v-for="item in translationMenuItems">
                                        <Button
                                            v-if="item.code !== pageLanguage"
                                            :key="item.url || item"
                                            @click="onLanguageClick(item)"
                                            newTab
                                            iconPosition="left"
                                            variant="secondary"
                                        >
                                            {{ item.name }}
                                        </Button>
                                    </template>
                                </template>
                                <Button
                                    v-if="twitterUsername"
                                    :url="getSocialUrl(twitterUsername, 'twitter')"
                                    newTab
                                    iconPosition="left"
                                    variant="secondary"
                                >
                                    {{ twitterUsername }}
                                    <template #icon>
                                        <Icon name="twitter" />
                                    </template>
                                </Button>
                                <Button
                                    v-if="linkedInUsername"
                                    :url="getSocialUrl(linkedInUsername, 'linkedin')"
                                    newTab
                                    iconPosition="left"
                                    variant="secondary"
                                >
                                    {{ linkedInUsername }}
                                    <template #icon>
                                        <Icon name="linkedin" />
                                    </template>
                                </Button>

                                <Button v-if="emailUrl" :url="emailUrl" variant="tertiary">{{ $t('email') }}</Button>
                            </div>
                        </template>

                        <template #right>
                            <div class="link-panel-right">
                                <CopyLinkButton />
                                <PrintButton />
                            </div>
                        </template>
                    </PageControls>
                </div>

                <div v-if="avatar" class="sub-hero-visual">
                    <Avatar :image="avatar" size="x-large" class="avatar-desktop" :isColor="true"></Avatar>
                </div>
            </div>
        </div>

        <section v-if="personDetails?.about" class="summary">
            <div class="summary-content">
                <Typography as="h2" variant="h3-display" transform="capitalize" class="summary-heading">
                    {{ $t('about the expert', 1, { locale: pageLanguage }) }}
                </Typography>

                <div class="summary-layout">
                    <div class="summary-left">
                        <div v-if="personDetails?.about" class="about">
                            <HiddenContent>
                                <RichTextElements :elements="personDetails?.about" />
                            </HiddenContent>
                        </div>

                        <ContentCardBlock
                            v-if="pinnedWork"
                            :related="pinnedWork"
                            size="33"
                            :hideImage="true"
                            :hideDescription="true"
                            class="pinned-work"
                        >
                            <template #icon>
                                <Icon name="pin" class="pin" />
                            </template>
                        </ContentCardBlock>
                    </div>

                    <div class="summary-right">
                        <div v-if="personDetails?.affiliations?.length" class="credential-section">
                            <Eyebrow opacity="light">{{ $t('affiliations', 1, { locale: pageLanguage }) }}</Eyebrow>

                            <div class="credential-layout">
                                <ul class="affiliations">
                                    <li v-for="affiliation in personDetails.affiliations" :key="affiliation.id">
                                        <Anchor :url="affiliation.path">
                                            <Typography
                                                v-if="affiliation.contentType === 'programs'"
                                                variant="body-display-large"
                                                class="affiliation-label"
                                                >{{ affiliation.title }} Program</Typography
                                            >
                                            <Typography v-else variant="body-display-large" class="affiliation-label">{{
                                                affiliation.title
                                            }}</Typography>
                                        </Anchor>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="credential-section" v-if="featuredTopicsAndRegions?.length">
                            <div class="credential-layout">
                                <div class="areas-of-expertise">
                                    <Eyebrow opacity="light">{{
                                        $t('areas of expertise', 1, { locale: pageLanguage })
                                    }}</Eyebrow>
                                    <TagCluster :items="featuredTopicsAndRegions"> </TagCluster>
                                </div>
                            </div>
                        </div>

                        <div class="credential-section">
                            <div v-if="personDetails?.education" class="education">
                                <Eyebrow opacity="light">{{ $t('education', 1, { locale: pageLanguage }) }}</Eyebrow>
                                <Typography variant="body-display-small">{{ personDetails.education }}</Typography>
                            </div>
                            <div v-if="personDetails?.languages" class="languages">
                                <Eyebrow opacity="light">{{ $t('languages', 1, { locale: pageLanguage }) }}</Eyebrow>
                                <Typography variant="body-display-small">{{ personDetails.languages }}</Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <Tier v-if="featuredContent?.length" :eyebrow="$t('featured work', 1, { locale: pageLanguage })">
            <div class="works">
                <ContentCardBlock
                    v-for="contentItem in featuredContent"
                    :key="contentItem.id"
                    size="33"
                    :related="contentItem"
                    :pageLanguage="pageLanguage"
                    context="inline"
                />
            </div>
        </Tier>

        <Tier
            v-if="featuredCollections?.length"
            background="grey"
            :eyebrow="$t('featured collections', 1, { locale: pageLanguage })"
        >
            <div class="collections">
                <ContentCardBlock
                    v-for="collection in featuredCollections"
                    size="50"
                    :key="collection.id"
                    :related="collection"
                    :hideDescription="true"
                    :pageLanguage="pageLanguage"
                    class="collection"
                />
            </div>
        </Tier>

        <IndexTier
            class="index"
            :title="`${$t('All work from', 1, { locale: pageLanguage })} ${title}`"
            :renderedFilters="renderedFilters"
            :contentType="contentType"
            :pageId="id"
            :pageLanguage="pageLanguage"
        >
            <template #results="{ results }">
                <ContentCardBlock
                    v-for="result in results"
                    :key="result.id"
                    :related="result"
                    orientation="wide"
                    context="inline"
                    class="result"
                    :hideDateCard="true"
                    :pageLanguage="pageLanguage"
                    :showPublicationDate="true"
                />
            </template>
        </IndexTier>
    </div>
</template>

<script setup>
const { pageLanguage } = usePageDataStore();
const props = defineProps({
    avatar: {
        type: Object,
    },
    email: {
        type: String,
        default: () => '',
    },
    id: {
        type: String,
        default: () => '',
    },
    personDetails: {
        type: Object,
    },
    personTitle: {
        type: String,
        default: () => '',
    },
    socialNetworks: {
        type: Array,
        default: () => [],
    },
    title: {
        type: String,
        default: () => '',
    },
    type: {
        type: String,
        default: '',
    },
    hasProfile: {
        type: Boolean,
    },
    contentType: {
        type: String,
        default: '',
    },
    path: {
        type: String,
    },
    locale: {
        type: String,
        default: '',
    },
    center: {
        type: Array,
        default: () => [],
    },
    translations: {
        type: Object,
        default: () => {},
    },
});
let jsonImg = '';
if (props.avatar?.url) {
    jsonImg = props.avatar.url;
}
let canonicalURL = 'https://carnegieendowment.org' + props.path;
if (props.locale) {
    canonicalURL = canonicalURL + '?lang=' + props.locale;
}
let jsonTitle = '';
if (props.personTitle) {
    jsonTitle = props.personTitle;
}

const route = useRoute();
let canonicalURLForMeta = 'https://carnegieendowment.org' + route.path;
if (props.locale) {
    canonicalURLForMeta = canonicalURLForMeta + '?lang=' + props.locale;
}

const jsonld = {
    '@context': 'https://schema.org',
    '@type': 'ProfilePage',
    mainEntity: {
        '@type': 'Person',
        name: props.title,
        image: jsonImg,
        description: jsonTitle,
    },
    url: canonicalURL,
};
useHead({
    script: [
        {
            hid: 'breadcrumbs-json-ld',
            type: 'application/ld+json',
            innerHTML: JSON.stringify(jsonld),
        },
    ],
    link: [
        {
            rel: 'canonical',
            href: canonicalURLForMeta,
        },
    ],
});
const { locales } = useI18n();
const translationMenuItems = computed(() => {
    const items = [];
    if (props?.translations) {
        Object.keys(props.translations).forEach((key) => {
            if (props.translations[key]) {
                items.push({
                    code: key,
                    name: getLocaleName(locales, key) || key,
                    url: `?lang=${key}`,
                });
            }
        });
    }

    return items;
});

function onLanguageClick(locale) {
    reloadAppWithLocale(locale.code);
}

// 2024-11-13 FIXME removed type from default rendered filters because it is broken
const renderedFilters = ['topics', 'regions', 'years'];

const isExternal = props.type === 'external' && props?.hasProfile;
const eyebrowText = computed(() => {
    if (props.type === 'internal' && props?.personDetails?.personType.includes('staff')) {
        return 'staff';
    } else if (props.type === 'internal' && props?.personDetails?.personType.includes('carnegieExpert')) {
        return 'experts';
    } else return '';
});

const twitterUsername = props.socialNetworks?.find((item) => item.network === 'twitter')?.handle;
const linkedInUsername = props.socialNetworks?.find((item) => item.network === 'linkedin')?.handle;

const emailUrl = props.email && `mailto:${props.email}`;

const featuredTopicsAndRegions = props.personDetails?.featuredTopicsAndRegions?.map((item) => {
    const { id } = item;
    const { path, title } = item.tag.value;
    return {
        id,
        path,
        title,
    };
});

const pinnedWork = props.personDetails?.pinnedHeroWork?.[0];

const featuredContent = computed(() =>
    getContentFeedItems(props.personDetails.featuredContent?.feed, props.personDetails.featuredContent?.dynamic),
);

const featuredCollections = props.personDetails?.featuredCollections;

const headerAttributesStore = useHeaderAttributesStore();
// since we aren't syndicating scholars to centers, all we can do is use center affiliation and hope we don't have a scholar tagged to two-non-DC centers
let GTMCenter = 'CEIP';
if (props.center.includes('china')) {
    GTMCenter = 'Carnegie China';
} else if (props.center.includes('europe')) {
    GTMCenter = 'Carnegie Europe';
} else if (props.center.includes('russiaEurasia')) {
    GTMCenter = 'Carnegie Russia Eurasia';
} else if (props.center.includes('india')) {
    GTMCenter = 'Carnegie India';
} else if (props.center.includes('middleEast')) {
    GTMCenter = 'Carnegie Middle East Center';
}
const GTMLang = props.locale;
onMounted(() => {
    window.dataLayer.push({
        Center: GTMCenter,
        Doctype: 'Bio Page',
        Expert: '',
        'Taxonomy Tags Program': '',
        'Taxonomy Special Project': '',
        'Taxonomy Tags Regions': '',
        'Taxonomy Tags Topics': '',
        'Page Language': GTMLang,
        event: 'new_data_layer',
    });
    headerAttributesStore.updateHeaderStyleAttributes({ background: 'white' });
});
</script>

<style lang="scss" scoped>
.hero {
    position: relative;
    overflow: visible;
    z-index: -1;

    @include media-query(mobile) {
        overflow: hidden;
    }
}

.sub-hero {
    background-color: color(grey, default, 0.6);
}

.sub-hero-content {
    @include content-section;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: var(--col-gutter);
}

.sub-hero-visual {
    position: relative;
    @include z-index(above-arbitrary);

    @include media-query(mobile) {
        display: none;
    }
}

.link-panel {
    flex: 1;
}

.link-panel-left,
.link-panel-right {
    height: 100%;
    display: flex;
    align-items: center;
    @include media-query(mobile) {
        display: block;
        button {
            margin-bottom: 0.5em;
            margin-right: 0.5em;
        }
    }
}

.link-panel-left {
    gap: 2.4rem;
}

.link-panel-right {
    gap: 0.4rem;
}

.avatar-desktop {
    margin-top: -50%;
    padding-left: 1.8rem;
    padding-right: 1.8rem;
}

.summary {
    background-color: color(grey, default, 0.6);
}

.summary-content {
    @include content-section;
    padding-top: 1rem;
    padding-bottom: 6.2rem;

    @include media-query(tablet) {
        padding-top: 4.1rem;
    }

    @include media-query(phone) {
        padding-top: 2.5rem;
    }
}

.summary-heading {
    @include content-area;
}

.summary-layout {
    @include grid;

    @include media-query(mobile) {
        display: flex;
        flex-direction: column;
        gap: 2.8rem;
    }
}

.summary-right {
    @include content-area;
}

.summary-left {
    grid-column: 1 / span 6;
}

.summary-right {
    grid-column: 8 / span 5;
}

.about {
    margin-bottom: 3.3rem;
    @include content-area;
}

.about-content {
    height: 20rem;
    overflow: hidden;

    &.--is-revealed {
        height: auto;
        overflow: visible;
    }
}

.pin {
    opacity: 0.5;
}

.affiliation-label {
    color: color(blue);
    opacity: 0.75;
}

.credential-section {
    &:not(:last-child) {
        padding-bottom: 2.4rem;
        border-bottom: 1px solid palette(border);
    }

    &:not(:first-child) {
        padding-top: 2.4rem;
    }
}

.credential-layout {
    display: flex;
    gap: 3.7rem;
    justify-content: space-between;
}

.education {
    padding-bottom: vertical-space(2);
}

.collections {
    @include grid($layout: '50');
}

.works {
    @include grid($layout: '33');
}
</style>
